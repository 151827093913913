import { defineStore } from 'pinia'
import type { Rating, GetReviewsParams } from '@academica-box/api/reviews'
import type { CompaniesResponseData, MappedVendorLanding, PartnersCarouselData } from '@/api/partners'
import type { VendorReview } from '@/types/vendor'

export const usePartnersStore = defineStore('partners', {
  state: () => ({
    companies: null,
    landing: null,
    carousel: null,
    reviews: null,
    rating: null,
  } as {
    companies: CompaniesResponseData | null;
    landing: MappedVendorLanding | null;
    carousel: PartnersCarouselData | null;
    reviews: VendorReview[] | null;
    rating: Rating | null;
  }),

  actions: {
    async getCompanies() {
      const { $api, $sentry } = useNuxtApp()

      if (this.companies) {
        return new Promise((resolve) => {
          return resolve(this.companies)
        })
      }

      try {
        const response = await $api.partners.getCompanies()

        this.companies = response

        return response
      } catch (error) {
        $sentry.captureException(error)
      }
    },

    async getLanding(slug: string) {
      const { $api, $sentry } = useNuxtApp()

      try {
        const response = await $api.partners.getLanding(slug)

        this.landing = response

        return response
      } catch (error) {
        $sentry.captureException(error)
      }
    },

    async getPartnersCarousel() {
      const { $api, $sentry } = useNuxtApp()

      if (this.carousel) {
        return new Promise((resolve) => {
          return resolve(this.carousel)
        })
      }

      try {
        const response = await $api.partners.getPartnersCarousel()

        this.carousel = response
      } catch (error) {
        $sentry.captureException(error)
      }
    },

    async getReviews(params?: GetReviewsParams) {
      const { $api, $sentry } = useNuxtApp()

      try {
        const response = await $api.reviews.getReviews(params)

        this.reviews = response.data

        return response
      } catch (error) {
        $sentry.captureException(error)
      }
    },

    async getRating(id?: number) {
      const { $api, $sentry } = useNuxtApp()

      try {
        const response = await $api.reviews.getCompanyRating([id || -1])

        this.rating = response[id || -1] || null

        return response
      } catch (error) {
        $sentry.captureException(error)
      }
    },

    clearLanding() {
      this.landing = null
    }
  },
})
